import React from "react";
import Layout from "../containers/layout";

import { graphql } from 'gatsby'
import BasePortableText from '@sanity/block-content-to-react'
import SEO from '../components/seo';

export const query = graphql`
  {
    site: sanityPrivacyStatement(_id: {regex: "/(drafts.|)privacyStatement/"}) {
      _rawBody(resolveReferences: {maxDepth: 5})
    }
  }
`

class PrivacyStatement extends React.Component {
  constructor(queryData) {
    super(queryData);
    const {data} = queryData;

    this.data = data;
  }
  render() {
    return(
      <Layout>
        <SEO title="Privacy Verklaring" />
        <section className="content-block">
          <div className="wrap">
            <div className="row">
              <div className="col-lg-9">
                <BasePortableText blocks={this.data.site._rawBody}/>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default PrivacyStatement
